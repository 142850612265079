<template>
    <section class="d-flex a-center justify-content-around position-relative pr-4 bg-view" :style="`background-image:url(${imgLogin[indice]})`">
        <div class="col-5" />
        <div class="box px-4 custom-scroll pb-4">
            <div id="sign-in-button" />
            <ValidationObserver ref="validacion">
                <p class="title-box text-center mb-3 lh-40">
                    Ingresar con tu <br /> número telefonico
                </p>
                <div class="row mx-0 j-center position-relative">
                    <div class="col-12 text-general f-15 my-2 mt-4">
                        Enviaremos un código de verificación al telefono.
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Número de telefono">
                            <el-input v-model="model.telefono" :placeholder="`${tipo == 1 ? 'Número de telefono' : 'Escriba tu correo electronico'}`" class="w-100 br-10" :class="tipo == 1 ? 'options-number' : 'options-email'">
                                <div v-if="tipo == 1" slot="prepend" class="d-middle">
                                    <img :src="bandera" class="br-2" width="35" height="20" />
                                    <el-select v-model="model.indicativo" placeholder="+ 52" @change="seleccionarBandera">
                                        <el-option
                                        v-for="item in paises"
                                        :key="item.indicativo"
                                        :label="'+'+item.indicativo"
                                        :value="item.indicativo"
                                        />
                                    </el-select>
                                </div>
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <div class="btn-general cr-pointer br-12" @click="validarUsuario">
                            Recibir Código
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <p class="text-general f-15">
                            ¿No tienes una cuenta?
                        </p>
                    </div>
                    <div class="col-12 my-2">
                        <div class="btn-secondary  h-32px px-3 py-1 cr-pointer br-12 d-middle-center" @click="$router.push({name: 'registro'})">
                            Registrarme
                        </div>
                    </div>
                    <div class="col-12 my-2">
                        <div class="btn-secondary  h-32px px-3 py-1 cr-pointer br-12 d-middle-center" @click="$refs.modalOtrosMedios.toggle()">
                            Ingresar con otro medio
                        </div>
                    </div>
                    <!-- Mensajes de alerta -->
                    <div v-if="error == 'ER01'" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            ¿Seguro eres tú?
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            No logro recordarte, asegúrate de <br /> escribir bien tu número telefono.
                        </p>
                    </div>
                    <div v-else-if="error == 'ER03'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un administrador.
                        </p>
                    </div>
                    <div v-else-if="error == 'ER04'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un {{ $config.vendedor }}.
                        </p>
                    </div>
                    <div v-else-if="error == 'ER02'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            ¡Opps!
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Tu cuenta se encuentra inactiva
                        </p>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <modal-medios-ingreso ref="modalOtrosMedios" @initGoogle="startConnectionProvider('google')"/>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
const VUEX_RUTA = 'auth'
// Importante para autenticación por medio de firebase
import firebase from '~/library/firebase'; // No quitar
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Service from '~/services/auth';
import Registro from '~/services/registro'

export default {
    layout: 'auth',
    components: {
        modalMediosIngreso: () => import('./partials/modalOtrosMedidosIngreso.vue'),
    },
    data(){
        return {
            model: {
                telefono: null,
                indicativo: 52,
            },
            indice: 0,
            error: null,
            tipo: 1,
            tipoLogin: 1,
            tipoError: '',
            paises: [],
            bandera: null,
        }
    },
    computed: {
        ...mapGetters({
            imgCliente :'x_parametros/imgCliente',
            token: `${VUEX_RUTA}/obtenerToken`,
            usuarioLogueado: `${VUEX_RUTA}/obtenerUsuario`,
        }),
        imgLogin(){
            return this.imgCliente.login;
        }
    },
    mounted(){
        setInterval(() => {
            let cantidad = this.imgLogin.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
        this.getPaises();
    },
    methods: {
        ...mapActions({
            iniciarSesionGoogle: `${VUEX_RUTA}/iniciarSesionGoogle`,
        }),

        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
        }),
        async getPaises(){
            try {
                const { data } = await Service.getPaises();
                this.paises = data.paises;
                this.bandera = data.paises.find((item) => parseInt(item.indicativo) === 52).bandera;
            } catch(e){
                this.errorCatch(e)
            }
        },

        async validarUsuario(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                this.error = null;
                const params = {
                    telefono: this.model.telefono
                };
                const { data } = await Service.existeUsuario(params)
                
                if(!data.existe){
                    return this.error = 'ER01';
                }

                if(data.existe && data.error){
                    return this.error = data.error;
                }
                this.generarCodigo();
            } catch(e){
                this.errorCatch(e)
            }
        },

        async generarCodigo(){
            try {
                const numeroCelular = `+${this.model.indicativo}${this.model.telefono}`;
                const data = await Registro.enviarCodigoRegistro(numeroCelular);
            
                if(!data){
                    return this.notificacion('Mensaje', 'Ocurrio un error al intentar enviar el código', 'warning');
                }
                this.$store.commit('auth/ALMANECAR_DATOS_USUARIO_REGISTRO', this.model);
                this.verificarCodigo();
            } catch (error){
                this.errorCatch(error)
            }
        },

        async verificarCodigo(){
            this.$router.push({name:'verificar.codigo.ingreso'});
        },


        seleccionarBandera(){
            this.bandera = this.paises.find((item) => item.indicativo === this.model.indicativo).bandera;
        },

        async startConnectionProvider(typeProvider){
            // Conectamos por medio de Firebase al proveedor indicado para autenticación
            if(typeProvider == 'google'){
                const provider = new GoogleAuthProvider();
                // Agregamos el permiso email al scope de la respuesta del proveedor (En este caso google)
                provider.addScope('email');
                // iniciamos con la conexión
                this.startConnectionGoogle(provider);
            }
        },

        async startConnectionGoogle(provider){
            const auth = getAuth();
            await signInWithPopup(auth, provider)
                .then((result) => {
                    /*
                    - result: Retornara la información que tiene google del usuario, como correo, nombre etc...
                    - credential: Retornara la información de acceso a google, como el token de acceso etc...
                    */
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const payload = {
                        provider: 'google',
                        token: credential.accessToken,
                        tipo: 3
                    };

                    this.iniciarSesionGoogle(payload)
                        .catch(this.almacenarError)
                        .finally(() => {
                            this.validarRedireccionarInicioSesion();
                        });
                }).catch((error) => {
                    // error: Retornara información acerca de los errores enviado por google
                    this.notificacion('No es posible', 'Ocurrio un error al intentar conectar con google', 'warning');
                });
        },

        validarRedireccionarInicioSesion(){
            if (this.existenErrores || this.existenErroresCamposVacios || _.isEmpty(this.token)){
                this.notificacion('No es posible ingresar', 'Correo electronico no registrado', 'warning');
                return;
            }
            
            this.$store.commit('home/setHomeCache',false)
            if(!this.usuarioLogueado.tienda){
                return this.$router.push({ name: 'tienda.agregar' });
            }
            this.$router.push({ name: 'home' });
        },
    },
}
</script>
<style lang="scss" scoped>

.bg-view{
    height: calc(100vh - 75px); 
    background-size:cover;
    background-repeat: no-repeat;
    transition: all 1s ease-out !important;
}

.box{
    width: 423px;
    height: 453px;
    box-shadow: 0px 1px 4px #00000014;
    border-radius: 12px;
    .title-box{
        font-size: 37px;
        color: #000000;
        font-weight: 600;
    }
}

.lh-40{
    line-height: 40px;
}

.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    left:-360px;top:-36px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}

// Media Queries

@media (min-width: 300px) and (max-width: 1204px) {
    section{
        background-image: inherit !important;
    }
    .col-5{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 780px) {
    .col-auto{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 648px) {
    section{
        padding-right: 0px !important;
        .box{
            width: 100%;
            height: 88vh;
            border-radius: inherit !important;
            &::-webkit-scrollbar-track{ 
                height: 6px; 
                width:0px; 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar{ 
                background-color:transparent; 
                width:0px; 
                height: 6px; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar-thumb{ 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
        }
    }
}
</style>
